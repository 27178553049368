import React from "react";

export default function DiscordRedirect() {
	window.location.replace('https://discord.gg/sD66Hnu5WA');

  return (<>
    <p>Please wait a moment, we're beaming you to our Discord server.</p>
  </>);
}

