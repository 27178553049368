import user from "./api/user";
import games from "./api/games";

//export const host = process.env.REACT_APP_HOST || "https://api.punkcake.club";
export const host = process.env.REACT_APP_HOST || "http://localhost:3796";

export const checkStatus = (res: Response): Response | Promise<never> => {
  if (res.ok) {
    return res;
  } else {
    if (res.status === 401) {
      window.location.href = "/";
      return res.text().then((msg: string) => {
        throw new Error(msg);
      });
    } else {
      return res.text().then((msg: string) => {
        throw new Error(msg);
      });
    }
  }
};

const api = {
  user,
  games,
};

export default api;
