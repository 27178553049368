import { checkStatus, host } from "../api";
import { User, UserGame, Key, UserGameLite } from "./_type";
import { KeyType } from "../constantes";

export interface MeResponse {
  user: User;
  jwt: string;
}

const user = {
  getAllAdmin: (): Promise<User[]> => {
    return fetch(`${host}/user/admin`, {
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getGames: (user: number)
   : Promise<UserGameLite[]> => {
    return fetch(`${host}/user/${user}/games`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      }
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  giveGame: ({
    body,
    user,
  }: {
    body: {
      game: number;
      types: number;
      reason: string;
    };
    user: number;
  }): Promise<UserGameLite> => {
    return fetch(`${host}/user/${user}/give`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  setRole: ({id, role}: {id: number; role: string;}): Promise<User> => {
    let body = {
      role: role
    };
    
    return fetch(`${host}/user/${id}/role`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  
  exchangeToken: (token: string): Promise<MeResponse> => {
    return fetch(`${host}/user/exchange`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ token }),
    })
      .then((res) => {
        if (res.ok) {
          return res;
        } else {
          return res.text().then((msg) => {
            throw new Error(msg);
          });
        }
      })
      .then((res) => res.json());
  },
  getMe: (): Promise<MeResponse> => {
    return fetch(`${host}/user/me`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res;
        } else {
          return res.text().then((msg: string) => {
            throw new Error(msg);
          });
        }
      })
      .then((res) => res?.json());
  },
  getMyGames: (): Promise<UserGame[]> => {
    return fetch(`${host}/user/games`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  refreshMe: (): Promise<UserGame[]> => {
    return fetch(`${host}/user/refresh`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  claimKey: ({
    gameId,
    type,
  }: {
    gameId: number;
    type: KeyType;
  }): Promise<Key> => {
    return fetch(`${host}/user/games/key`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
      body: JSON.stringify({ gameId, type }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
};

export default user;
