import React from "react";
import axios from 'axios';
import * as Yup from 'yup';

import buttCSS from '../style/Button.module.css';
import formCSS from '../style/Form.module.css';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';

import { useFormState } from '../utils/utils';


// connect/disconnect Patreon
// connect/disconnect Itch

// full name
// username
// email
// mail consent



export default function Profile(){
	
	const FormSchema = Yup.object().shape({
    full_name: Yup.string().min(1).max(96).required(),
    username: Yup.string().nullable().optional().default(undefined),
    email: Yup.string().email().nullable().optional().default(undefined),
    mail_consent: Yup.boolean(),
  });
	
	const initial: { [key: string]: any } = {
		full_name: '',
    username: '',
    email: '',
    mail_consent: false,
	};
	
  const [formState, handleInput, setFormState] = useFormState(initial);
  
	function text_input(name: string, tip: string | undefined, id: string, wrong: string | undefined){
		let schem = FormSchema.fields[id] as Yup.AnySchema;
		
		return (<Form.Group>
		  <Form.Label className={formCSS.label}>{name}:</Form.Label>
			{tip ? (
			<p className={formCSS.tip}>{tip}</p>
			) : ""}
			<Form.Control
			  type="text"
        value={formState[id]}
        onChange={handleInput}
        name="full_name"
        isInvalid={!schem || !schem.isValidSync(formState[id])}
			/>
			{ wrong ? (
				<Form.Control.Feedback type="invalid">
				{wrong}
				</Form.Control.Feedback>
			) : ""}
		</Form.Group>);
	}
  
  return (<Container>
	  <h1>Profile</h1>
		
		{ text_input(
		  "Full Name",
			"We'll use this to credit you in the games if you are a Partner Chef!",
			"full_name",
			"Please write something down"
		) }
		
		{ text_input(
		  "Username",
			"We might use this someday",
			"username",
			undefined
		) }
		
		{ text_input(
		  "E-mail",
			"",
			"email",
			"Please give us a valid email"
		) }

		
		<p />
		
		<button
      className={buttCSS.butt}
      disabled={!FormSchema.isValidSync(formState)}
    >
      Save
    </button>
  
  </Container>);
}

