import React, { useState } from 'react';

import style from './Index.module.css';
import buttCSS from '../../style/Button.module.css';

import api from '../../utils/api';
import { UserGameLite, Game, User } from '../../utils/api/_type';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';

import { dateToString, useFormState } from '../../utils/utils';

import * as Yup from 'yup';
import { allKeyArray, KeyType, KEY_CONVERSION } from '../../utils/constantes';

const userStatuses = [
  '',
  'dev',
  'friend',
  'discord_mod',
  'press'
];

const giveReasons = [
  '',
  'gift',
  'patreon',
  'dev',
  'friend',
  'discord_mod',
  'press'
];

export default function UserList() {
  const queryClient = useQueryClient();

  const { data: users, isLoading: loadingUsers } = useQuery(
    'usersAdmin',
    api.user.getAllAdmin
  );
  
  const { data: games, isLoading: loadingGames } = useQuery(
    'games',
    api.games.getAll
  );
  
  const [selUser, setSelUser] = useState(2);
  const [status, setStatus] = useState("n/a");
  
  const { data: owned, isLoading: loadingOwned } = useQuery(
    'owned',
    () : Promise<UserGameLite[]> => {
      return api.user.getGames(selUser);
    }
  );
  
  
  if (users){
    users.sort((a,b) => {
      return a.full_name.toUpperCase().localeCompare(b.full_name.toUpperCase());
    });
    console.log(users);
  }
  else {
    console.log("Still loading users");
  }
  
  if (games){
    games.sort((a,b) => {
      return b.num - a.num;
    });
  }
  
  function userMap(){
    if (!users)
      return "";
    
    let l = "";
    
    function checkNewL(name: string){
      let n = name.charAt(0).toUpperCase();
      if (n != l){
        l = n;
        return (<><b>{l}</b><br></br></>);
      }
      else {
        return "";
      }
    }
    
    return (<>{
      users.map(u => (<>
        {checkNewL(u.full_name)}
        <a href={"#"+u.full_name}>{u.full_name}</a><br></br>
      </>))
    }</>);
  }
  
  
  const [form, handleInput, setForm] = useFormState({
    game: 0,
    flag: 0,
    reason: ''
  });
  const [role, setRole] = useState('');
  function handleStatusChange(e: any){
    setRole(e.target.value);
  }
  
  function selectUser(user: User){
    setSelUser(user.id);
    queryClient.invalidateQueries('owned');
    setForm({
      game: games ? games[0].id : 0,
      flag: 0x10,
      reason: 'gift'
    });
    setRole(user.status);
    
    setStatus("n/a");
  }
  
  const { mutate: giveGame } = useMutation(api.user.giveGame, {
    onSuccess: (data, variable) => {
      setStatus("Done!");
      queryClient.invalidateQueries('owned');
    },
    onError: (err) => {
      console.log("ERROR: "+err);
    }
  });
  
  const { mutate: setUserRole } = useMutation(api.user.setRole, {
    onSuccess: (data: User) => {
      if (!users)
        return;
      
      setStatus("Done!");
      let i = users.findIndex(u => (u.id === data.id));
      users[i] = data;
    },
    onError: (err) => {
      console.log("ERROR: "+err);
    }
  });

  
  function userGameList(){
    if (loadingGames || games === undefined)
      return (<Spinner animation="border" />);
    
    function list(){
      if (!games)
        return "";
      
      if (loadingOwned || owned === undefined)
        return (<Spinner animation="border" />);
      
      function getTier(g: Game){
        if (!owned)
          return "";
        
        let f = owned.find((a) => {return a.GameId === g.id;});
        
        if (f) {
          return "0x"+f.has.toString(16)+" because "+(f.reason ?? "[no reason]");
        }
        else {
          return "-";
        }
      }
      
      return (<p>
        {games.map(g => (<>
          {g.title}: {getTier(g)}<br />
        </>))}
      </p>);
    }

    return (<>
      {list()}
      
      <div>
        <p style={{display: "inline-block"}}>Give</p>
        
        <select
          value={form.game}
          onChange={handleInput}
          name="game"
          style={{display: "inline-block", margin: '8px'}}
        >
          <option value='0'>[game]</option>
          {games.map(g => (
            <option value={g.id}>{g.title}</option>
          ))}
        </select>
       
        <p style={{display: "inline-block"}}>with flags*</p>
        <input
          value={form.flag}
          onChange={handleInput}
          name="flag"
          style={{display: "inline-block", margin: '8px'}}
        />
        
        <p style={{display: "inline-block"}}>because</p>
        <select
          value={form.reason}
          onChange={handleInput}
          name="reason"
          style={{display: "inline-block", margin: '8px'}}
        >
          {giveReasons.map(
            r => (<option value={r}>{r}</option>)
          )}
        </select>
        
        <button
          className={buttCSS.butt}
          onClick = {() => {
            giveGame({
              body: {
                game: form.game,
                types: form.flag,
                reason: form.reason
              },
              user: selUser
            });
            setStatus("Workin'");
          }}
        >
          Give it!
        </button>
        
        <p style={{display: "inline-block"}}>{status}</p>
      </div>
      
      <p>*0x10 is gifted game, 0x01 is patreon game, 0x02 is bandcamp key, 0x04 is special version.</p>
      
      <div>
        <p style={{display: "inline-block"}}>Set</p>
        
        <select
          value={role}
          onChange={handleStatusChange}
          style={{display: "inline-block", margin: '8px'}}
        >
          {userStatuses.map(s => (
            <option value={s}>{s}</option>
          ))}
        </select>
      
        <p style={{display: "inline-block"}}>status</p>
        
        <button
          className={buttCSS.butt}
          onClick = {() => {
            setUserRole({id: selUser, role: role});
            setStatus("Workin'");
          }}
        >
          Set it!
        </button>
        
        <p style={{display: "inline-block"}}>{status}</p>
      </div>
     
    </>)
    
  }


  return (<>
    <div style={{
      position: "fixed",
      width: "200px",
      overflowY: "scroll",
      top: "0",
      bottom: "0",
      fontFamily: '"Courier New", monospace',
      fontSize: '10px'
    }}>
      <h1>List of users</h1>
      {loadingUsers || users === undefined ? (
        <Spinner animation="border" />
      ) : (
      <p>
        {userMap()}
      </p>
      )}
    </div>
  
    <Container>
      <h1>List of users</h1>
      {loadingUsers || users === undefined ? (
        <Spinner animation="border" />
      ) : (
        <>
        {users.map((u) => (<>
          <hr></hr>
          <h5 id={u.full_name} style={{display:'inline-block'}}>{u.full_name} :: {u.id}</h5>
          { selUser == u.id ? (<>
            <p></p>
            {userGameList()}
          </>) : (
            <button
              className={buttCSS.butt}
              style={{display:'inline-block', marginLeft: '8px'}}
              onClick={() => {
                // target this user
                selectUser(u);
              }}>
              +
            </button>
          )}
        </>))}
        </>
      )}
    </Container>
  </>);
}

