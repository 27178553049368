import React, { useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import api from './utils/api';
import { JWT_PERIOD } from './utils/constantes';

import { ReactQueryDevtools } from 'react-query/devtools';

// Components
import NeedLogin from './components/NeedLogin';
import TopBar from './components/TopBar';
import Footer from './components/Footer';

// Pages
import Index from './pages/Index';
import MyGames from './pages/MyGames';
import Profile from './pages/Profile';
import IndexAdmin from './pages/admin/Index';
import UserListAdmin from './pages/admin/UserList';
import ItchRedirect from './pages/redirect/ItchRedirect'
import DiscordRedirect from './pages/redirect/DiscordRedirect'
import { UserContextType, useUser } from './utils/context/User';
import {
  HasCheckLoginContextType,
  useHasCheckLogin,
} from './utils/context/HasCheckLogin';

import { initTheme } from './utils/theme';

function App() {
  const [, setUser] = useUser() as UserContextType;
  const [, setHasCheckLogin] = useHasCheckLogin() as HasCheckLoginContextType;

  useEffect(() => {
    const hash = window.location.hash;

    if (hash === '') {
      api.user
        .getMe()
        .then((data) => {
          console.log('%cReconexion avec le JWT', 'color: orange;');

          localStorage.setItem('jwt', data.jwt);

          setUser(data.user);
          setHasCheckLogin(true);
          setInterval(refetchMe, JWT_PERIOD);
        })
        .catch((err) => {
          console.log(err);
          setHasCheckLogin(true);
          setUser(undefined);
        });
    } else {
      let search = new URLSearchParams(window.location.hash.replace('#', '?'));

      if (search.get('return_login') === null) {
        setHasCheckLogin(true);
        setUser(undefined);
      } else {
        api.user
          .exchangeToken(search.get('return_login') as string)
          .then((data) => {
            localStorage.setItem('jwt', data.jwt);

            window.location.hash = '';
            setUser(data.user);
            setHasCheckLogin(true);
            setInterval(refetchMe, JWT_PERIOD);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }

    function refetchMe() {
      api.user
        .getMe()
        .then((data) => {
          console.log('%cAcualisation du JWT', 'color: orange;');

          localStorage.setItem('jwt', data.jwt);

          setUser(data.user);

          setInterval(refetchMe, JWT_PERIOD);
        })
        .catch((err) => {
          console.log(err);
          setUser(undefined);
        });
    }
  }, [setUser, setHasCheckLogin]);

  initTheme();

  return (
    <>
      <Router>
        <TopBar />
        <Switch>
          <Route path="/itch-redirect">
            <ItchRedirect />
          </Route>
					
					<Route path="/discord">
						<DiscordRedirect />
					</Route>
        
          <Route path="/games">
            <NeedLogin>
              <MyGames />
            </NeedLogin>
          </Route>
        
          <Route path="/profile">
              <Profile />
          </Route>

          <Route path="/admin">
            <NeedLogin level={100}>
              <IndexAdmin />
            </NeedLogin>
          </Route>
          
          <Route path="/admin-user-list">
            <NeedLogin level={100}>
              <UserListAdmin />
            </NeedLogin>
          </Route>

          <Route exact path="/">
            <Index />
          </Route>
          
          <Route path="/home">
            <Index />
          </Route>

          <Redirect to="/" />
        </Switch>
      </Router>

      <Footer />
      <ReactQueryDevtools />
    </>
  );
}

export default App;
