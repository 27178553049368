import React from "react";

import { useHistory } from "react-router";
import { HasCheckLoginContextType, useHasCheckLogin } from "../utils/context/HasCheckLogin";
import { UserContextType, useUser } from "../utils/context/User";

interface Props {
  children: any;
  level?: number;
}

export default function NeedLogin({
  children,
  level = 0,
}: Props): React.ReactElement {
  const history = useHistory();

  const [user] = useUser() as UserContextType;
  const [hasCheckLogin] = useHasCheckLogin() as HasCheckLoginContextType;

  if (hasCheckLogin) {
    if (user !== undefined) {
      if (user.permission >= level) {
        return children;
      } else {
        history.push("/");
        return <></>;
      }
    } else {
      history.push("/");
      return <></>;
    }
  } else {
    return <></>;
  }
}
