import style from '../style/Footer.module.css';

export default function Footer() {
  return (
    <footer className={style.footer}>
      <p>
        Come join us on our Discord!! <a href='https://discord.gg/dpQx647USm' target="_blank">Do it!</a>
      </p>
    </footer>
  );
}

