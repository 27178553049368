import React from "react";
import axios from 'axios';

import { checkStatus, host } from "../../utils/api";


export default function ItchRedirect() {
  let queryString = window.location.hash.slice(1);
  let params = new URLSearchParams(queryString);
  let accessToken = params.get("access_token");
  
  if (!accessToken){
    return (<p>
      You shouldn't be here.
    </p>);
  }
    
  let url = `${host}/itch/redirect/${accessToken}`;

  return (<>
    <meta http-equiv="Refresh" content={`0; url='${url}'`} />
    <p>Please wait a moment.</p>
  </>);
}

