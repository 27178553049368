import React from 'react';
import Container from "react-bootstrap/Container";
import { GameDex } from "../components/GameCard";

export default function MyGames() {
  return (<>
    <Container>
      <h1>My Games</h1>
    </Container>
    {GameDex()}
  </>);
}

