import React, { ChangeEvent, useState } from 'react';

import style from './Index.module.css';
import buttCSS from '../../style/Button.module.css';
import gameCSS from '../../style/GameCard.module.css';

import api from '../../utils/api';
import { Game } from '../../utils/api/_type';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';

import { dateToString, useFormState } from '../../utils/utils';

import * as Yup from 'yup';
import { allKeyArray, KeyType, KEY_CONVERSION } from '../../utils/constantes';

export default function IndexAdmin() {
  const queryClient = useQueryClient();

//{ Loading data
  const { data: games, isLoading } = useQuery(
    'gamesAdmin',
    api.games.getAllAdmin
  );

  if (!games){
    console.log("Still loading games...");
  }
  else{
    games.sort((a,b) => {
      return b.num-a.num;
    });
    console.log(games);
  }
//}

//{ Adding & Editing Games
  const FormSchema = Yup.object().shape({
    title: Yup.string().min(2).max(255).required(),
    emoji: Yup.string().max(16).required(),
    slug:  Yup.string().min(1).max(255).required(),
    
    num:   Yup.number().min(1).required(),
    year:  Yup.number().min(2021).required(),
    month: Yup.number().min(1).max(12).required(),
    
    description: Yup.string().min(0).required(),
    publishDate: Yup.string().min(2).required(),
    
    trailer:  Yup.string().nullable().optional().default(undefined),
    itch:     Yup.string().nullable().optional().default(undefined),
    bandcamp: Yup.string().nullable().optional().default(undefined),
    steam:    Yup.string().nullable().optional().default(undefined),
  });
  
  const [formState, handleInput, setFormState] = useFormState({
    title: '',
    emoji: '',
    slug:  '',
    
    num:   0,
    year:  0,
    month: 0,
    
    description: '',
    publishDate: '',
    
    trailer:  undefined as string | undefined,
    itch:     undefined as string | undefined,
    bandcamp: undefined as string | undefined,
    steam:    undefined as string | undefined,
  });

  const { mutate: addGame } = useMutation(api.games.addGame, {
    onSuccess: (data: Game) => {
      queryClient.invalidateQueries('gamesAdmin');

      setIsOpenModalAddGame(false);
      setIsOpenModalEditGame(data.id);
    },
  });

  const { mutate: editGame } = useMutation(api.games.updateGame, {
    onSuccess: (data, variable) => {
      queryClient.invalidateQueries('gamesAdmin');
      // setIsOpenModalEditGame(undefined);
    },
  });

  const [isOpenModalAddGame, setIsOpenModalAddGame] = useState(false);
  const [isOpenModalEditGame, setIsOpenModalEditGame] = useState<
    number | undefined
  >(undefined);

  function handleAddGame() {
    if (FormSchema.isValidSync(formState)) {
      if (isOpenModalEditGame !== undefined) {
        editGame({
          id: isOpenModalEditGame,
          body: { ...formState, publishDate: new Date(formState.publishDate) },
        });
      } else {
        addGame({ ...formState, publishDate: new Date(formState.publishDate) });
      }
    }
  }
  
  const z = (i: number) => (i < 10 ? `0${i}` : `${i}`);
  function formatDate(date: string) {
    let goodDate = new Date(date);

    return `${goodDate.getFullYear()}-${z(goodDate.getMonth() + 1)}-${z(
      goodDate.getDate()
    )}`;
  }
//}

//{ Adding assets
  const [fileList, setFileList] = useState<FileList | null>(null);
  const [fileDest, setFileDest] = useState("thumb");
  // Possible values: thumb // screen // art // logo // video
  function handleFileChange(e: ChangeEvent<HTMLInputElement>){
    setFileList(e.target.files);
  }
  
  function handleFileDestChange(e: ChangeEvent<HTMLSelectElement>){
    setFileDest(e.target.value);
  }
  
  const { mutate: setThumb } = useMutation(api.games.setThumb, {
    onSuccess: (data, variable) => {
      queryClient.invalidateQueries('gamesAdmin');
    },
  });
  
  function handleUploadFiles(){
    if (!fileList || !fileDest)
      return;
    
    if (!isOpenModalEditGame)
      return;
    
    if (fileDest === "thumb")
      setThumb({body: fileList, id: isOpenModalEditGame});
  }
//}

//{ Adding keys
  const [addKeyForm, handleAddKeyForm, setAddKeyForm] = useFormState({
    type: 'itchio',
    keys: '',
  });
  const { mutate: addKeys } = useMutation(api.games.addGameKeys, {
    onSuccess: (data, varialbe) => {
      setIsModalAddKeysOpen(undefined);
    },
  });

  const [isModalAddKeysOpen, setIsModalAddKeysOpen] = useState<
    number | undefined
  >(undefined);

  function handleAddKeys() {
    addKeys({
      id: isModalAddKeysOpen as number,
      type: addKeyForm.type as KeyType,
      keys: addKeyForm.keys,
    });
  }
  
  function keyCount(g: any, k: any) {
    let cl = g.keyClaimed.find((cl: any) => cl.type === k.type);
    
    return (<>
      {k.type}: {cl ? cl.count : "0"}/{k.count}
    </>)
  }
//}

  return (
    <>
      <Container>
        <h1>All Games</h1>
        <Button
          className={style.butt}
          onClick={() => {
            setIsOpenModalAddGame(true);
            setFormState();
          }}
        >
          Add Game
        </Button>

        {isLoading || games === undefined ? (
          <Spinner animation="border" />
        ) : (
          <div className={gameCSS.gameGrid}>
            {games.map((g) => (
              <div className={gameCSS.gameCard}>
                <div className={gameCSS.header}>
                  <h2 className={gameCSS.gameTitle}>{g.title}</h2>
                  <p className={gameCSS.gameEmoji}>{g.emoji ?? "X"}</p>
                </div>
                <p className={gameCSS.gameDesc}>{g.description}</p>
                <p style={{display: "flex", flex: "1 1 auto", margin: "0px"}} />
                <p>{dateToString(g.publishDate)}</p>
                <div>
                  <h4>Keys (claimed/total)</h4>
                  <ul>
                    {g.keyCount.map((key) => (
                      <li key={key.type}>
                        {keyCount(g, key)}
                      </li>
                    ))}
                  </ul>
                </div>
                <footer>
                  <button
                    className={buttCSS.butt}
                    onClick={() => {
                      setIsOpenModalEditGame(g.id);
                      setFormState({
                        title: g.title,
                        emoji: g.emoji,
                        slug:  g.slug,
                        
                        num:   g.num,
                        year:  g.year,
                        month: g.month,
                        
                        description: g.description,
                        publishDate: formatDate(g.publishDate),
                        
                        trailer:  g.trailer,
                        itch:     g.itch,
                        bandcamp: g.bandcamp,
                        steam:    g.steam,
                      });
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className={buttCSS.butt}
                    onClick={() => {
                      setIsModalAddKeysOpen(g.id);
                      setAddKeyForm();
                    }}
                  >
                    Add Keys
                  </button>
                </footer>
              </div>
            ))}
          </div>
        )}
      </Container>

      <Modal
        show={isOpenModalAddGame || isOpenModalEditGame !== undefined}
        onHide={() => {
          setIsOpenModalAddGame(false);
          setIsOpenModalEditGame(undefined);
        }}
        style = {{backgroundColor: "var(--bg)"}}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isOpenModalEditGame !== undefined ? 'Update game' : 'Add Game'}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group>
            <Form.Label>Title & Emoji</Form.Label>
            <div>
              <Form.Control
                style={{width: '80%', display:'inline-block'}}
                type="text"
                value={formState.title}
                onChange={handleInput}
                name="title"
                isInvalid={!FormSchema.fields.title.isValidSync(formState.title)}
              />
              <Form.Control
                style={{width: '19%', marginLeft: '1%', display:'inline-block'}}
                type="text"
                value={formState.emoji ?? ""}
                onChange={handleInput}
                name="emoji"
                isInvalid={!FormSchema.fields.emoji.isValidSync(formState.emoji)}
              />
            </div>
            <div>
              <Form.Label style={{width: '19%', display:'inline-block'}}>Slug</Form.Label>
              <Form.Control
                type="text"
                style={{width: '80%', marginLeft: '1%', display:'inline-block'}}
                value={formState.slug}
                onChange={handleInput}
                name="slug"
                isInvalid={
                  !FormSchema.fields.slug.isValidSync(
                    formState.slug
                  )
                }
              />
            </div>
            <div>
              <Form.Label style={{width: '10%', display:'inline-block'}}>month</Form.Label>
              <Form.Control
                type="text"
                style={{width: '20%', marginLeft: '1%', display:'inline-block'}}
                value={formState.month}
                onChange={handleInput}
                name="month"
                isInvalid={
                  !FormSchema.fields.month.isValidSync(
                    formState.month
                  )
                }
              />
              <Form.Label style={{width: '10%', marginLeft: '2%', display:'inline-block'}}>year</Form.Label>
              <Form.Control
                type="text"
                style={{width: '20%', marginLeft: '1%', display:'inline-block'}}
                value={formState.year}
                onChange={handleInput}
                name="year"
                isInvalid={
                  !FormSchema.fields.year.isValidSync(
                    formState.year
                  )
                }
              />
              <Form.Label style={{width: '10%', marginLeft: '2%', display:'inline-block'}}>num</Form.Label>
              <Form.Control
                type="text"
                style={{width: '20%', marginLeft: '1%', display:'inline-block'}}
                value={formState.num}
                onChange={handleInput}
                name="num"
                isInvalid={
                  !FormSchema.fields.num.isValidSync(
                    formState.num
                  )
                }
              />
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              value={formState.description}
              onChange={handleInput}
              name="description"
              isInvalid={
                !FormSchema.fields.description.isValidSync(
                  formState.description
                )
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Publish Date</Form.Label>
            <Form.Control
              type="date"
              value={formState.publishDate}
              onChange={handleInput}
              name="publishDate"
              isInvalid={
                !FormSchema.fields.publishDate.isValidSync(
                  formState.publishDate
                )
              }
            />
          </Form.Group>
          
          <Form.Group>
            <div style={{display: "flex", flexFlow:"row"}}>
            <Form.Label>Trailer</Form.Label>
            <Form.Control
              type="text"
              value={formState.trailer}
              onChange={handleInput}
              name="trailer"
              isInvalid={
                !FormSchema.fields.trailer.isValidSync(
                  formState.trailer
                )
              }
            />
            </div>
            <div style={{display: "flex", flexFlow:"row"}}>
            <Form.Label>Itch.io</Form.Label>
            <Form.Control
              type="text"
              value={formState.itch}
              onChange={handleInput}
              name="itch"
              isInvalid={
                !FormSchema.fields.itch.isValidSync(
                  formState.itch
                )
              }
            />
            </div>
            <div style={{display: "flex", flexFlow:"row"}}>
            <Form.Label>Bandcamp</Form.Label>
            <Form.Control
              type="text"
              value={formState.bandcamp}
              onChange={handleInput}
              name="bandcamp"
              isInvalid={
                !FormSchema.fields.bandcamp.isValidSync(
                  formState.bandcamp
                )
              }
            />
            </div>
            <div style={{display: "flex", flexFlow:"row"}}>
            <Form.Label>Steam</Form.Label>
            <Form.Control
              type="text"
              value={formState.steam}
              onChange={handleInput}
              name="steam"
              isInvalid={
                !FormSchema.fields.steam.isValidSync(
                  formState.steam
                )
              }
            />
            </div>
          </Form.Group>
          
          <input
            type="file"
            multiple
            onChange={handleFileChange}
          />
          <select
            value={fileDest}
            onChange={handleFileDestChange}
            style={{display: "inline-block", margin: '8px'}}
          >
            <option value="">[choose one]</option>
            <option value="thumb">Thumbnail</option>
            <option value="screen">Screenshots</option>
            <option value="art">Game art</option>
            <option value="logo">Logo</option>
            <option value="video">Video</option>
          </select>
          <button
            className = {buttCSS.butt}
            onClick={handleUploadFiles}
          >
            Upload
          </button>
      
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setIsOpenModalAddGame(false);
              setIsOpenModalEditGame(undefined);
            }}
          >
            Close
          </Button>
          <Button
            className={style.butt}
            onClick={handleAddGame}
            disabled={!FormSchema.isValidSync(formState)}
          >
            {isOpenModalEditGame !== undefined ? 'Update' : 'Add'}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isModalAddKeysOpen !== undefined}
        onHide={() => setIsModalAddKeysOpen(undefined)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Add keys to{' '}
            {games?.filter((g) => g.id === isModalAddKeysOpen)[0]?.title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group>
            <Form.Label>Type</Form.Label>
            <Form.Control
              as="select"
              onChange={handleAddKeyForm}
              name="type"
              value={addKeyForm.type}
            >
              {allKeyArray.map((k) => (
                <option value={k} key={k}>
                  {KEY_CONVERSION[k].name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>Keys</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              name="keys"
              value={addKeyForm.keys}
              onChange={handleAddKeyForm}
              isInvalid={addKeyForm.keys === ''}
            />
            <Form.Text>One per line</Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button
            className={buttCSS.butt}
            onClick={() => setIsModalAddKeysOpen(undefined)}
          >
            Close
          </button>
          <button
            className={buttCSS.butt}
            onClick={handleAddKeys} disabled={addKeyForm.keys === ''}
          >
            Add
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
