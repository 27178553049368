import { useState, useContext, createContext } from 'react';

export type HasCheckLoginContextType = [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>
];

const HasCheckLoginContext = createContext<
  HasCheckLoginContextType | undefined
>(undefined);

export function HasCheckLoginContextProvider({
  children,
}: {
  children: React.ReactElement;
}) {
  const [hasCheckLogin, setHaskCheckLogin] = useState(false);

  return (
    <HasCheckLoginContext.Provider value={[hasCheckLogin, setHaskCheckLogin]}>
      {children}
    </HasCheckLoginContext.Provider>
  );
}

export function useHasCheckLogin() {
  return useContext(HasCheckLoginContext);
}