import axios from 'axios';
import { checkStatus, host } from '../api';
import { KeyType } from '../constantes';
import { AdminGame, Game } from './_type';

const games = {
  getAllAdmin: (): Promise<AdminGame[]> => {
    return fetch(`${host}/games/admin`, {
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getAll: (): Promise<Game[]> => {
    return fetch(`${host}/games`)
      .then(checkStatus)
      .then((res) => res.json());
  },
  getTotal: (): Promise<number> => {
    return fetch(`${host}/games/total`)
      .then(checkStatus)
      .then((res) => res.json());
  },
  addGame: (body: {
    title: string;
    description: string;
    emoji: string;
    slug: string;
    publishDate: Date;
    
    num: number,
    year: number,
    month: number,
    
    trailer?: string;
    itch?: string;
    bandcamp?: string;
    steam?: string;
  }): Promise<Game> => {
    return fetch(`${host}/games`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  updateGame: ({
    body,
    id,
  }: {
    body: {
      title?: string;
      description?: string;
      emoji?: string;
      slug?: string;
      publishDate?: Date;
      
      num?: number;
      year?: number;
      month?: number;
    
      trailer?: string;
      itch?: string;
      bandcamp?: string;
      steam?: string;
    };
    id: number;
  }): Promise<Game> => {
    return fetch(`${host}/games/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  
  // KEYS
  addGameKeys: ({
    id,
    type,
    keys,
  }: {
    id: number;
    type: KeyType;
    keys: string;
  }): Promise<string> => {
    return fetch(`${host}/games/${id}/keys`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
      body: JSON.stringify({ type, keys }),
    })
      .then(checkStatus)
      .then((res) => res.text());
  },
  
  // ASSETS
  setThumb: ({body, id}: {body: FileList, id: number}): Promise<string> => {
    const data = new FormData();
    for (let i=0; i<body.length; i++){
      let file = body.item(i) as File;
      data.append(`file-${i}`, file, file.name);
      //data.append(`file`, file, file.name);
    }

//    return fetch(`${host}/games/${id}/thumb`, {
//      method: 'POST',
//      headers: {
//        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
//        'content-type': 'multipart/form-data',
//      },
//      body: data,
//    })
    return axios.post(`${host}/games/${id}/thumb`, data, {
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || '')
      }
    })
      .then((res) => { console.log(res); return res.statusText; });
  }
};

export default games;
