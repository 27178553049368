import React, { useState } from 'react';

import daynightCSS from '../style/DayNight.module.css';
import topbarCSS from '../style/TopBar.module.css';
import buttCSS from '../style/Button.module.css';

import punkcakePNG from '../assets/punkcakex1.png';
import punkcake_whitePNG from '../assets/punkcakex1_white.png';
import day_theme_icon from '../assets/icons/day_theme_icon.png';
import night_theme_icon from '../assets/icons/night_theme_icon.png';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import { useHistory } from 'react-router';
import { UserContextType, useUser } from '../utils/context/User';
import { host } from "../utils/api";

import { getTheme, switchTheme } from '../utils/theme'


export default function TopBar(): React.ReactElement {
  const history = useHistory();

  const [user, setUser] = useUser() as UserContextType;
  function redirectToLogin() {
    window.location.href = host + "/patreon/login";
  }
  
  function ThemeButton(){
    const [night, setNight] = useState(getTheme()==="night");
    
    return (<div className={daynightCSS.slot}>
      <button
        className = {daynightCSS.icon}
        onClick={() => {
          switchTheme();
          setNight(!night);
        }}
      >
        <img
          className = {daynightCSS.pic}
          alt = "Change visual theme"
          src = {(getTheme()==="night") ? night_theme_icon : day_theme_icon}
        />
        <p className = {daynightCSS.label}>
          Switch to {night ? "light" : "dark"} theme
        </p>
      </button>
    </div>);
  }
  
  if (!user)
    return ThemeButton();

  function redirect(e: any) {
    e.preventDefault();
    history.push(e.target.getAttribute('href'));
  }

  function logout() {
    localStorage.removeItem('jwt');
    setUser(undefined);

    history.push('/');
  }
  
  let perm = user ? user.permission : -1;
  let name = user ? user.full_name : "guest";

  return (<>
    <Navbar className={topbarCSS.bar} variant={getTheme()==="night" ? "dark" : "light"} expand="lg">
      <Navbar.Toggle aria-controls="topbar-nav" />
      <Navbar.Collapse id="topbar-nav">
        <Nav className="mr-auto">
        
          <Nav.Link className={topbarCSS.link} href="/" onClick={redirect}>
            <img alt="PUNKCAKE Délicieux" className={topbarCSS.punkcake} src={getTheme()==="night" ? punkcake_whitePNG : punkcakePNG} />
          </Nav.Link>
          
          
          {perm >= 0 ? (<>
            <Nav.Link className={topbarCSS.link} href="/games" onClick={redirect}>
              <b className = {topbarCSS.text}>
                My Games
              </b>
            </Nav.Link>
          </>) : ""}
          {perm >= 100 ? (<>
            <Nav.Link className={topbarCSS.link} href="/admin" onClick={redirect}>
              <b className = {topbarCSS.text}>
                Admin
              </b>
            </Nav.Link>
            <Nav.Link className={topbarCSS.link} href="/admin-user-list" onClick={redirect}>
              <b className = {topbarCSS.text}>
                UserList
              </b>
            </Nav.Link>
          </>) : ""}
        </Nav>
        
        {perm >= 0 ? (<>
        <p
          style={{
            marginLeft: 'auto',
            marginRight: '5px',
            marginBottom: 0,
            display: "inline-block"
          }}
        >
          {name}
        </p>
        <button
          className={buttCSS.butt}
          style={{display: "inline-block"}}
          onClick={logout}
        >
          Disconnect
        </button>
        </>) :
        (<>
        <p
          style={{
            marginLeft: 'auto',
            marginRight: '5px',
            marginBottom: 0,
            display: "inline-block"
          }}
        >
          Not logged in
        </p>
        <button
          className={buttCSS.butt}
          style={{display: "inline-block"}}
          onClick={redirectToLogin}
        >
          Connect
        </button>
        </>)}
      </Navbar.Collapse>
    </Navbar>
    {ThemeButton()}
  </>);
}
