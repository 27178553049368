import { useState } from 'react';

export function useFormState<T>(initialValue: T) {
  const [internalState, setInternalState] = useState(initialValue);

  function handleInput(e: any) {
    if (e.target.getAttribute('type') === 'file') {
      setInternalState((before) => {
        return {
          ...before,
          [e.target.getAttribute('name') || '']: e.target.files,
        };
      });
    } else if (e.target.getAttribute('type') === 'checkbox') {
      setInternalState((before) => {
        return {
          ...before,
          [e.target.getAttribute('name') || '']: e.target.checked,
        };
      });
    } else {
      setInternalState((before) => {
        return {
          ...before,
          [e.target.getAttribute('name') || '']: e.target.value,
        };
      });
    }
  }

  function resetState(newValue?: T) {
    if (newValue === undefined) {
      setInternalState(initialValue);
    } else {
      setInternalState(newValue);
    }
  }

  return [internalState, handleInput, resetState] as const;
}

function zero(int: number): string {
  return int < 10 ? '0' + int : '' + int;
}

export function dateToString(d: string | Date): string {
  if (d === '') return '';

  let date: Date;

  if (d instanceof Date) {
    date = d;
  } else {
    date = new Date(d);
  }

  return `${zero(date.getDate())}/${zero(
    date.getMonth() + 1
  )}/${date.getFullYear()}`;
}
