export const JWT_PERIOD = 40 * 60 * 1000;

export type KeyType = 'itchio' | 'itchiodeluxe' | 'steam' | 'bandcamp';
export const allKeyArray = ['itchio', 'steam', 'itchiodeluxe', 'bandcamp'];

export const keyFlags = {
  itchio: 0x11,
  steam: 0x11,
  bandcamp: 0x02,
  itchiodeluxe: 0x04
};

export const KEY_CONVERSION: {
  [key: string]: { name: string; redeem_url: string };
} = {
  itchio: {
    name: 'Itch.io',
    redeem_url: '',
  },
  itchiodeluxe: {
    name: 'Itch.io Deluxe',
    redeem_url: '',
  },
  steam: {
    name: 'Steam',
    redeem_url: 'https://store.steampowered.com/account/registerkey?key=',
  },
  bandcamp: {
    name: 'Original Soundtrack',
    redeem_url: 'https://bandcamp.com/yum?code=',
  },
};
