import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { QueryClient, QueryClientProvider } from "react-query";

import "./index.css";
import ContextProvider from "./utils/context/ContextProvider";

// Create a client
const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
      </ContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
