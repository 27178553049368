import React from "react";
import { HasCheckLoginContextProvider } from "./HasCheckLogin";
import { UserContextProvider } from "./User";

export default function ContextProvider({children}: {children: React.ReactElement}) {
  return <HasCheckLoginContextProvider>
    <UserContextProvider>
      {children}
    </UserContextProvider>
  </HasCheckLoginContextProvider>
}