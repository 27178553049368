

const themes = new Map([
  [ "day", [
    ["--bg", "#ffffff"],
    ["--text", "#000000"],
    
    ["--cardborder", "#8f8f8f"],
    ["--cardbg", "#ffffff"],
    ["--descbg", "#e8e8e8"],
    
    ["--bright0", "0%"],
    ["--faintlvl", "50%"],
  ]],
  [ "night", [
    ["--bg", "#24202c"],
    ["--text", "#ffffff"],
    
    ["--cardborder", "#afafaf"],
    ["--cardbg", "#000000"],
    ["--descbg", "#34303f"],
    
    ["--bright0", "1000%"],
    ["--faintlvl", "75%"],
  ]],
  
]);

let curTheme = "";

export function initTheme(){
  let theme = localStorage.getItem("theme");
  if (theme){
    setTheme(theme);
    return;
  }
  
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches){
    setTheme("night");
    return;
  }

  setTheme("day");
}

export function getTheme(){
  return curTheme;
}

export function switchTheme(){
  if (curTheme === "night")
    setTheme("day");
  else
    setTheme("night");
}

export function setTheme(key: string){
  let d = themes.get(key);
  
  if (!d){
    alert("Couldn't find theme '"+key+"'.");
    return;
  }
  
  curTheme = key;
  localStorage.setItem("theme", key);
  
  //console.log(d);
  for (const pair of d){
    document.body.style.setProperty(pair[0],pair[1]);
  }
}

