import React, {useState} from "react";
import { useQuery } from 'react-query';

import style from "./Index.module.css";
import buttCSS from "../style/Button.module.css";

import { GameShow } from "../components/GameCard";

import api, { host } from "../utils/api";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { UserContextType, useUser } from "../utils/context/User";

import copy_icon from "../assets/icons/copy_icon.png";
import leave_icon from "../assets/icons/leave_icon.png";
import play_icon from "../assets/icons/play_icon.png";
import itch_icon from "../assets/icons/itch_icon.png";
import gameCSS from "../style/GameCard.module.css";


export default function Index() {
  const [user] = useUser() as UserContextType;
  const [thumbHover, setThumbHover] = useState("");
	
	const { data: gameCount, isLoading: loadingGameCount } = useQuery("gameTotal", api.games.getTotal);

  function redirectToLogin(via: string) {
    return () => { window.location.href = host + `/${via}/login`; };
  }
  
  
  function copyKey(){
    let text = document.getElementById(`hello`) as HTMLInputElement;
    if (text){
      text.select();
      text.setSelectionRange(0, 99999);
    }
    
    navigator.clipboard.writeText("hello");
  }
  

  return (<>
    <Container>
      <h1 className={style.title} style={{textShadow: '0px -4px #ff30a0', marginBottom:'48px' }}>PUNKCAKE Délicieux</h1>

      <p>
        Hi! We're a small team making lots of games! We're mostly known for making Shotgun King: The Final Checkmate but we also made {gameCount ? ((gameCount as number)-1) : "many"} other games! We release those games as part of a monthly subscription on Patreon!
        <br></br><br></br>
        </p><p><a href = "https://patreon.com/punkcake">💜 Subscribe on Patreon for 3€ per month!</a><br></br>
        </p><p><a href = "https://twitter.com/punkcake_delice">📢 Follow us on Twitter!</a><br></br>
        </p><p><a href = "https://discord.gg/dpQx647USm">💬 Join our community on Discord!</a>
        </p><p><a href = "https://drive.google.com/drive/folders/1HxtDFBZYn8VOSYZPbp_L9o4-pTDB5Btb?usp=sharing">📝 Are you press? Here's our presskit!</a><br></br>
        </p><p><br></br>
        We are two french game developpers, Benjamin Soulé and Rémy Devaux, and one spanish composer, Pentadrangle! We're constantly exploring different game genres and new ideas. We strive to make games that are original, well-made, and fun to play!
      </p>

      {user !== undefined ? (
        <>
          <p>
            Connected as <b>{user.username}</b>.
          </p>
          <p>
            Go to <Link to="/games">your games</Link>
          </p>
        </>
      ) : (
        <>
          <br></br>
					<p>Are you already a <b>Patreon subscriber</b>?</p>
          <button className = {buttCSS.butt} onClick={redirectToLogin('patreon')}>
            Log in with Patreon and redeem your games!
          </button>
          <p></p>
          <p>No? That's ok, you can also connect with <b>itch.io</b>!</p>
          <button className = {buttCSS.butt} onClick={redirectToLogin('itch')}>
            Log in with itch.io and enter the punkcake zone!
          </button>
        </>
      )}
      
      
    </Container>
    
    {GameShow()}
      
  </>);
}