import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { Game, UserGame } from '../utils/api/_type';
import api, { host } from '../utils/api';
import { KeyType, keyFlags, KEY_CONVERSION } from '../utils/constantes';

import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";

import bandcamp_icon from "../assets/icons/bandcamp_icon.png";
import itch_icon from "../assets/icons/itch_icon.png";
import youtube_icon from "../assets/icons/youtube_icon.png";
import steam_icon from "../assets/icons/steam_icon.png";
import copy_icon from "../assets/icons/copy_icon.png";
import leave_icon from "../assets/icons/leave_icon.png";
import play_icon from "../assets/icons/play_icon.png";

import gameCSS from "../style/GameCard.module.css";
import buttCSS from "../style/Button.module.css";


export function GameShow(){
  const { data: games, isLoading: loadingGames } = useQuery("allGames", api.games.getAll);
  
  return GameList(games, loadingGames, undefined, false);
}

export function GameDex(){
  const { data: games, isLoading: loadingGames } = useQuery("allGames", api.games.getAll);
  const { data: owned, isLoading: loadingOwned } = useQuery("myGames", api.user.getMyGames);
  
  return GameList(games, loadingGames, owned, loadingOwned);
}

export function GameCard(game: Game){
  return GameList([game], false, undefined, false);
}


const ownPhrases: { [key: string]: string } = {
  not:         "You do not own this game.",
  itch:        "✨You own this game on Itch!🌌",
  steam:       "✨You own this game on Steam!🚂",
  itchsteam:   "✨You own this game on Itch & Steam!🎮",
  patreon:     "✨You own this game as a Patreon supporter!✨",
  gift:        "🎉You own this game as a gift from us!🎁",
  dev:         "🥞You own this game as a Punkcake dev!🤘",
  friend:      "✨You own this game as a Punkcake friend!✨",
  discord_mod: "⚖You own this game as a Discord mod!🙏",
  press:       "🎥You own this game because you're press!✍",
};

const months = [
  "mis", "Jan", "Feb", "Mar", "Apr", "May", "Jun", 'Jul', "Aug", "Sep", "Oct", "Nov", "Dec"
];

function upcoming(num: number, year: number, month: number): Game{ // generate data for upcoming game card
  return {
    id: 999,
    title: "[Coming Soon]",
    emoji: "❓",
    slug: "coming-soon",
    
    num: num,
    year: year,
    month: month,
    
    description: "We're still working on this one!",
    publishDate: "",
    createdAt: "",
    updatedAt: "",
    
    thumbnail: true,
    
    itch: "",
    steam: "",
    trailer: "",
    bandcamp: "",
    
    itch_id: 999999
  };
}

export function GameList(games: Game[] | undefined, loadingGames: boolean, owned: UserGame[] | undefined, loadingOwned: boolean) {
  const queryClient = useQueryClient();

  const { mutate: claimKey } = useMutation(api.user.claimKey, {
    onSuccess: (data, variable) => {
      if (!owned) return;
      
      let game = owned.find(g => g.GameId===data.GameId);
      if (game){
        game.keys.push(data);
      }
      queryClient.setQueryData("myGames", owned);
    },
    onError: () => {
      window.alert('There is no key of this type for this game');
    },
  });


  const [thumbHover, setThumbHover] = useState("");
  let dex = (owned !== undefined || loadingOwned);
  
  const [refreshing, setRefreshing] = useState(false);
  function refreshButton(){
    function refresh(){
      setRefreshing(true);
      api.user.refreshMe().then(newOwned => {
        if (newOwned)
          queryClient.setQueryData("myGames", newOwned);
        
        setRefreshing(false);
      });
    }
    
    if (refreshing){
      return (<Container>
        <Spinner animation="border" />
      </Container>);
    }
    else {
      return (<Container>
        <button
          onClick={refresh}
          className={buttCSS.butt}
        >
          Refresh
        </button>
      </Container>);
    }
  }
  
  let seasons = [] as Game[][];
  
  if (!games){
    if (loadingGames)
      console.log("Still loading games...");
    return (<><Spinner animation="border" /></>);
  }
  
  games.sort((a,b) => {
    return b.num - a.num;
  });
  console.log(games);
  
  if (!games[0])
    return;
  
  let year = games[0].year;
  let seas = [] as Game[];
  
  if (!dex && games[0].month < 12){ // add card for upcoming game
    seas.push(upcoming(games[0].num+1, games[0].year, games[0].month+1));
  }
  
  for (const g of games){
    if (g.year !== year){
      seasons.push(seas);
      seas = [];
      year = g.year;
    }
    
    seas.push(g);
  }
  seasons.push(seas);
  
  function card(game: Game){
    let link = owned ? owned.find(a => a.GameId === game.id) : null;
    
    let grey = (dex && (!link || link.has === 0));
    
    function titledesc(){
      return (<>
        <div className={gameCSS.header}>
          <h2 className={gameCSS.gameTitle}>{game.title}</h2>
          <p className={gameCSS.gameEmoji}>{game.emoji}</p>
          <p className={gameCSS.gamePeriod}>{months[game.month]}. '{(game.year % 100)}</p>
        </div>
        {(game.thumbnail) ? (
          <div className={gameCSS.gameThumb}>
            {thumbHover === game.slug ? (<img
              alt={`${game.title} animated preview`}
              className={gameCSS.anim}
              src = {`${host}/static/${game.slug}/thumbnail/anim.gif`}
            />) : ""}
            <img
              alt={`${game.title} art`}
              onMouseEnter = {()=>{setThumbHover(game.slug)}}
              className={gameCSS.still}style={grey ? {filter: "grayscale(100%)"} : {}}
              src = {`${host}/static/${game.slug}/thumbnail/still.jpg`}
            />
            <img
              className={gameCSS.play}
              alt=''
              src = {play_icon}
            />
          </div>
        ) : ""}
        <p className={gameCSS.gameDesc}>{game.description}</p>
        <p style={{display: "flex", flex: "1 1 auto", margin: "0px"}} />
      </>);
    }
    
    function keys(){
      if (!dex)
        return "";
      
      if (!link || link.has === 0){
        return (
          <p className={gameCSS.ownerLine}>
            { ownPhrases.not }
          </p>
        );
      }
      
      function keyline(type: KeyType, platform: string){
        if (!link) return "";
        
        if ((link.has & keyFlags[type]) === 0)
          return;
        
        let key = link.keys.find(k => k.type === type as any);
        
        function copyKey(){
          if (!key) return;
          
          let text = document.getElementById(`${game.id} ${type} key`) as HTMLInputElement;
          if (text){
            text.select();
            text.setSelectionRange(0, 99999);
          }
          
          navigator.clipboard.writeText(key.key);
        }
        
        function redirectRedeem(){
          window.open(KEY_CONVERSION[type].redeem_url + key?.key);
        }
        
        function handleClaim(){
          claimKey({ gameId: game.id, type: type });
        }
        
        if (key) { // revealed key
          return (
            <div className={gameCSS.keyLine}>
              <p style={{
                position: "relative",
                top: "-2px",
                width: "100px",
                display: "flex",
                flex: "0 0 auto",
              }}>
                {platform}: 
              </p>
              <button
                onClick={copyKey}
                className={gameCSS.keyRevealed}
              >
                <input
                  id={`${game.id} ${type} key`}
                  value={key.key}
                  className={gameCSS.key}
                />
                <div style={{
                  position: "absolute",
                  right: "0px",
                  paddingRight: "4px",
                  paddingLeft: "4px",
                  borderStyle: "none none none dotted",
                  borderWidth: "2px",
                  borderColor: "var(--col)",
                  backgroundColor: "var(--bg)"
                }}>
                  <img src={copy_icon} alt="copy key" className={gameCSS.icon} style={{top: "-2px"}} />
                </div>
                <p className={gameCSS.label}>Copy to clipboard</p>
              </button>
              <button
                onClick={redirectRedeem}
                className={gameCSS.keyRevealed}
                style={{display: "flex", flex: "0 0 auto", marginLeft: "4px"}}
              >
                <img src={leave_icon} alt="redeem key" className={gameCSS.icon} />
                <p className={gameCSS.label}>Redeem on {platform}</p>
              </button>
            </div>
          );
        }
        else if (link.keyAvailable.includes(type)){ // redeem button
          return (
            <div className={gameCSS.keyLine}>
              <p style={{position: "relative", top: "-2px", width: "100px"}}>
                {platform}: 
              </p>
              <button
                onClick={handleClaim}
                className={buttCSS.butt}
                style={{
                  lineHeight: "8px",
                  paddingTop: "-6px",
                  textAlign: "center",
                  flex: "1 1 auto",
                  margin: "0px"
                }}>
                Claim
                <p className={buttCSS.label} style={{lineHeight:"18px"}}>
                  Get your key for this game!
                </p>
              </button>
            </div>
          );
        }
        
        return "";
      }
      
      return (<>
        <p className={gameCSS.ownerLine}>
          { ownPhrases[link.reason] ?? ownPhrases.patreon }
        </p>
        
        { keyline('itchio', "Itch.io") }
        { keyline('steam', "Steam") }
        { keyline('bandcamp', "Bandcamp") }
        { keyline('itchiodeluxe', "Secret") }
      </>);
    }
    
    function links(){
      function icon(pic: any, url: string, hint: string){
        return (
          <div className={gameCSS.gameIconSlot}>
            <a href={url}>
              <div className={gameCSS.gameIcon}>
                <p className={gameCSS.label}>
                  {hint}
                </p>
                <img className={gameCSS.pic}
                  src = {pic}
                  alt = {hint}
                />
              </div>
            </a>
          </div>
        );
      }
      
      return (<>
        <hr className={gameCSS.separator} />
        <div className={gameCSS.iconRow}>
          {game.trailer ? icon(youtube_icon, game.trailer, "Watch the trailer on Youtube") : null}
          {game.itch ? icon(itch_icon, game.itch, "View this game on Itch.io") : null}
          {game.steam ? icon(steam_icon, game.steam, "View this game on Steam") : null}
          {game.bandcamp ? icon(bandcamp_icon, game.bandcamp, "Listen to this game's soundtrack on Bandcamp") : null}
          <p className={gameCSS.gameNum}>
            #{("0" + (game.num??0)).slice(-2)}
          </p>
        </div>
      </>);
    }
    
    return (
      <div className={gameCSS.gameCard} style={grey ? {"--cardcol":"var(--grey)"} as React.CSSProperties : undefined}>
        {titledesc()}
        {keys()}
        {links()}
      </div>
    );
  }
  
  function curyear(){
    if (dex || !games)
      return "";
    
    let date = new Date();
    let year = date.getFullYear();
    
    if (year !== seasons[0][0].year){
      if (year % 2 === 1){
        return (<>
          <h1 className={gameCSS.gameYear}>{year}</h1>
          <Container>
          <p style={{textAlign: "center"}}>
            We're taking this year to rest, to catch up with last year's missing releases, to start preparing next year's, and to do maintenance work on the project in general!
          </p>
          </Container>
          <p style={{textAlign: "center"}}>
            See you in {(year+1)}!!
          </p>
        </>);
      }
      else {
        return (<>
          <h1 className={gameCSS.gameYear}>{year}</h1>
          <div className={gameCSS.gameGrid}>
          { card(upcoming(games[0].num, year, 1)) }
          </div>
        </>)
      }
    }
    
    return "";
  }
  
  return (<>
    {dex ? refreshButton() : ""}
    {curyear()}
    {seasons.map(seas => (<>
      <h1 className={gameCSS.gameYear}>{seas[0].year}</h1>
      <div className={gameCSS.gameGrid}>
        { seas.map(g => card(g)) }
      </div>
    </>))}
  </>);
}

